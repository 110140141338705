.about-wrapper {
    min-height: 100vh;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    color: #ffffff;
}




.about-wrapper p {
    font-size: 20px;
    max-width: 1200px;
    color: #999;
    margin-top: 0px;
    margin-top: 50px;
    text-align: center;
}
.about-wrapper h1 {
    font-size: 48px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-style: italic;
}





@media screen and (max-width: 800px) {

    .about-wrapper {
        padding-top: 75px;
    }

    .about-wrapper h1 {
        font-size: 32px;
        margin-bottom: 10px;
    }
    .about-wrapper p {
        font-size: 16px;
        width: 90%;
        margin-top: 30px;
    }

}












































