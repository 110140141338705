

.indexes-wrapper {
    min-height: 100vh;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.indexes-wrapper h1 {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
}



.indexes-caps-container {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    margin-top: 70px;
    flex-wrap: wrap;
}
.indexes-cap-item {
    font-size: 16px;
    font-family: 'Space Mono', 'monospace', -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif;
    color: #5eff31;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    background: #333333;
    border-radius: 12px;
    border: 1px solid #5eff31;
}
.indexes-cap-item:last-child {
    background: #5eff31;
    color: #333333;
}



.indexes-container {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    margin-top: 60px;
    padding-bottom: 40px;
}




.index-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    border-radius: 45px;
    background: rgba(10,10,10,0.75);
    border: 2px solid #5eff31;
    box-shadow: 0px 0px 40px rgba(94, 255, 49, 0.2);
    backdrop-filter: blur(5px);
    overflow: hidden;
}

.index-item-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
}
.index-item-top-left {
    display: flex;
    flex-direction: column;
}
.index-item-top-right {
    display: flex;
    align-items: end;
}
.index-item-top-right h3 {
    transform: translateY(-12px);
    margin-right: 20px;
    font-weight: 700;
}

.index-item h2 {
    font-size: 60px;
    color: #5eff31 !important;
}
.index-item h3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: -1px;
}
.index-item h2, .index-item h3, .index-item p {
    color: #ffffff;
}




.tv-lightweight-charts, #chart-container {
    width: calc(100% + 100px) !important;
    min-width: calc(100% + 100px) !important;
    margin-bottom: -23.5px;
    margin-top: 12px;
}
.tv-lightweight-charts {
    position: relative;
    border-top: 1px solid #333;

}




.tooltip {
    width: auto;
    height: auto;
    padding: 5px 15px;
    position: absolute;
    display: none;
    font-size: 30px;
    text-align: left;
    z-index: 1000;
    top: 0px;
    left: 12px;
    pointer-events: none;
    border: 1px solid;
    border-radius: 12px;
    background: #333;
    color: #5eff31;
    font-family: 'Space Mono', 'monospace', -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif;
}








@media screen and (max-width: 1200px) {

    .indexes-container {
        width: 92%;
        height: auto;
        display: flex;
        flex-direction: column;
        grid-gap: 50px;
        margin-top: 75px;
        padding-bottom: 40px;
    }

    .indexes-caps-container {
        width: 92%;
        grid-gap: 15px;
        margin-top: 40px;
    }
    .indexes-cap-item {
        width: 100%;
        font-size: 18px !important;
    }

}




@media screen and (max-width: 800px) {

    .index-item-top {
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin-left: 30px;
        margin-top: 0px;
    }

    .tv-lightweight-charts, #chart-container {
        width: calc(100% + 20px) !important;
        min-width: calc(100% + 20px) !important;
        margin-bottom: -10px;
        margin-top: 5px;
    }

    .indexes-wrapper {
        height: auto;
        min-height: 100vh;
        padding-top: 30px;
    }

    .indexes-container {
        width: 92vw;
        min-height: 110vh;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-gap: 15px;
        margin-top: 30px;
        padding-bottom: 50px;
    }

    .index-item {
        min-height: 300px;
        width: auto;
        padding: 25px 10px;
        border-radius: 35px;
    }
    .index-item h2 {
        font-size: 50px;
        margin-top: 5px;
    }

}


















































































