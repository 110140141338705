body {
  overflow: auto;
  width: 100vw;
  height: 100%;
  margin: 0;
  font-family: Inter, 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  background: #0a0a0a;
}
html {
  overflow: hidden;
  height: 100%;
}

.app {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Space Mono";
  font-weight: 700;
  src: url("../public/fonts/SpaceMono-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Space Mono";
  font-weight: 700;
  font-style: italic;
  src: url("../public/fonts/SpaceMono-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Space Mono";
  font-weight: 400;
  src: url("../public/fonts/SpaceMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Space Mono";
  font-weight: 400;
  font-style: italic;
  src: url("../public/fonts/SpaceMono-Italic.ttf") format("truetype");
}

.space {
  font-family: "Space Mono", monospace !important;
}

.green {
  color: #5eff31 !important;
}
.red {
  color: #ff0000 !important;
}

.rotate {
  transform: rotate(90deg);
}




h1 {
  font-size: 60px;
  letter-spacing: -0.8px;
  line-height: 120%;
}
















.big-button {
  font-family: "Space Mono", monospace;
  font-size: 35px;
  width: 500px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5eff31;
  color: #333;
  box-shadow: 0px 0px 40px rgba(94, 255, 49, 0.2);
  border-radius: 30px;
  outline: none;
  border: none;
}





.bottom-copyright {
  font-size: 16px;
  color: #333;
  margin-top: 50px;
  margin-bottom: -25px;
  text-align: center;
  font-style: italic;
}









@media screen and (max-width: 800px) {

  h1 {
    max-width: 380px;
    font-size: 45px;
  }

  .big-button {
    font-family: "Space Mono", monospace;
    font-size: 25px;
    width: 300px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5eff31;
    color: #0a0a0a;
    border-radius: 30px;
    outline: none;
    border: none;
  }


  .bottom-copyright {
    font-size: 12px;
    margin-bottom: -10px;
  }


}







