

.main-wrapper {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background: linear-gradient(180deg, rgba(10, 10, 10, 1) 1%, rgba(10, 10, 10, 0.25) 15%, rgba(10, 10, 10, 0.25) 70%, rgba(10, 10, 10, 1) 99%);
    overflow: hidden;
    padding: 50px;
    padding-top: 25px;
}

.home-back-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    pointer-events: none;
    z-index: -1;
    blend-mode: overlay;
    opacity: 0.03;
}

.timer-wrapper {
    height: 100vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.timer-wrapper p {
    font-size: 20px;
    max-width: 700px;
    color: #999;
    margin-top: 0px;
    margin-bottom: 50px;
    text-align: center;
}
.timer-wrapper h1 {
    text-align: center;
    text-transform: uppercase;
}
.timer {
    font-size: 125px;
    margin-top: 20px;
    margin-bottom: 25px;
}
.timer::last-letter {
    color: #5eff31;
}













@media screen and (max-width: 800px) {

    .main-wrapper {
        min-height: 200vh;
        height: auto;
        padding: 20px;
    }

    .timer {
        font-size: 50px;
        letter-spacing: -0.25px;
        margin-top: 25px;
        margin-bottom: 30px;
    }

    .timer-wrapper p {
        font-size: 16px;
        margin-bottom: 25px;
        padding: 0 5px;
    }

}












































