

.header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: -90px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    z-index: 10000;
}

.header-left {
    height: 40px;
    display: flex;
    align-items: center;
}
.header-left img {
    height: 100%;
    animation: clock 8s infinite step-end;
}
@keyframes clock {
    0% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(45deg);

    }
    25% {

        transform: rotate(90deg);
    }
    37.5% {
        transform: rotate(135deg);

    }
    50% {
        transform: rotate(180deg);

    }
    62.5% {
        transform: rotate(225deg);

    }
    75% {
        transform: rotate(270deg);

    }
    87.5% {
        transform: rotate(315deg);

    }
    100% {
        transform: rotate(360deg);

    }
}
.header-left span {
    font-size: 30px;
    color: #ffffff;
    margin-left: 23px;
    margin-top: -2px;
}


.header-right {
    height: 40px;
    display: flex;
    align-items: center;
}
.header-right p {
    font-size: 16px;
    color: #999;
    margin: 0 15px;
    cursor: pointer;
}








@media screen and (max-width: 800px) {


    .header-wrapper {
        margin-bottom: -70px;
        padding: 20px;
    }

    .header-left {
        height: 40px;
    }

    .header-left span {
        font-size: 24px;
        margin-left: 15px;
        margin-top: -2px;
    }


    .header-right {
        display: none;
    }

}






























































